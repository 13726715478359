<template>
    <footer class="bg-accent py-5 mt-10">
        <div class="max-w-[85rem] mx-auto">
            <div class="flex flex-row flex-wrap my-4 py-4">
                <div class="w-full md:w-5/12 text-white px-4">
                    <picture>
                        <source
                            :srcset="
                                cdnUrl +
                                '/assets/images/trusted-parts-logo-sm.png.webp, ' +
                                cdnUrl +
                                '/assets/images/trusted-parts-logo-sm.png.1.5x.webp 1.5x, ' +
                                cdnUrl +
                                '/assets/images/trusted-parts-logo-sm.png.2.0x.webp 2x'
                            "
                            type="image/webp" />
                        <img
                            :src="cdnUrl + '/assets/images/trusted-parts-logo-sm.png'"
                            alt="TrustedParts.com"
                            class="mb-4"
                            loading="lazy"
                            width="275"
                            height="40" />
                    </picture>
                    <div class="text-lg" v-html="$t('Layout.MissionStatement_Subtitle')"></div>
                    <p class="mt-4" v-html="$t('Layout.MissionStatement_Paragraph1')"></p>
                    <p v-html="$t('Layout.MissionStatement_Paragraph2')"></p>
                    <p v-html="$t('Layout.MissionStatement_Paragraph3')"></p>
                    <p v-html="$t('Layout.MissionStatement_LinkText')"></p>
                </div>
                <div class="hidden md:block md:w-1/12 lg:w-2/12 px-4"></div>
                <div class="w-full md:w-4/12 md:ml-1/12 lg:ml-2/12 px-4">
                    <FooterSearchBox />
                    <a
                        id="lnkFooterBulkSearch"
                        class="footer-bom-link"
                        :href="localePath('/bom/import')"
                        :title="$t('Layout.BulkSearchLinkText')">
                        <b v-html="$t('Layout.BulkSearchLinkText')"></b>
                    </a>

                    <div class="flex flex-row justify-between mr-1 ml-1">
                        <div class="w-full sm:w-5/12 flex flex-col items-start space-y-5">
                            <a
                                id="lnkFooterAboutUs"
                                :href="localePath('/about-us')"
                                :title="$t('Layout.LinkAbout')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkAbout") }}
                            </a>
                            <a
                                id="lnkFooterBlog"
                                href="/blog"
                                :title="$t('Layout.LinkBlog')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkBlog") }}
                            </a>
                            <a
                                id="lnkFooterContact"
                                :href="localePath('/contact-us')"
                                :title="$t('Layout.LinkContact')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkContact") }}
                            </a>
                            <a
                                id="lnkFooterDocumentation"
                                href="/docs/"
                                :title="$t('Layout.LinkDocumentation')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkDocumentation") }}
                            </a>
                            <a
                                href="http://www.ecianow.org/"
                                target="_blank"
                                rel="noopener"
                                title="ecianow.org"
                                class="link-white hover:underline">
                                ecianow.org
                            </a>
                        </div>
                        <div class="w-full sm:w-5/12 flex flex-col items-start space-y-5">
                            <a
                                id="lnkManufacturers"
                                :href="localePath('/manufacturers')"
                                :title="$t('Layout.LinkManufacturers')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkManufacturers") }}
                            </a>
                            <a
                                id="lnkPrivacyPolicy"
                                :href="localePath('/privacy-policy')"
                                :title="$t('PrivacyPolicy.Page_Title')"
                                class="link-white hover:underline">
                                {{ $t("PrivacyPolicy.Page_Title") }}
                            </a>
                            <a
                                id="lnkFooterSiteMap"
                                :href="localePath('/sitemap')"
                                :title="$t('Layout.LinkSitemap')"
                                class="link-white hover:underline">
                                {{ $t("Layout.LinkSitemap") }}
                            </a>
                            <a
                                id="lnkTermsAndConditions"
                                :href="localePath('/terms-and-conditions')"
                                :title="$t('TermsConditions.Link')"
                                class="link-white hover:underline">
                                {{ $t("TermsConditions.Link") }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="w-full md:w-2/12 lg:w-1/12 px-4 my-4">
                    <div class="footerIPTheftLogo">
                        <a
                            href="http://www.iprcenter.gov/disclaimer/"
                            :title="$t('Layout.IPTheftLogoTitle')"
                            rel="noopener">
                            <img
                                :src="cdnUrl + '/assets/images/reportiptheftbutton.png'"
                                :alt="$t('Layout.IPTheftLogoTitle')"
                                width="80"
                                height="80"
                                loading="lazy" />
                        </a>
                    </div>
                </div>

                <div class="w-full mt-5 px-4 text-center text-white">
                    ©{{ currentYear }} Electronic Components Industry Association
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const localePath = useLangPath();
const currentYear = new Date().getFullYear();
</script>
